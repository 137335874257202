import React from 'react';
import styles from './LoadingIndicator.module.css';
import sunflower from '../images/sunflower.png'; // Adjust the path if necessary

const LoadingIndicator = () => {
  return (
    <div className={styles.loadingContainer}>
      <img src={sunflower} alt="Loading..." className={styles.sunflower} />
      <p className>Cargando...</p>
    </div>
  );
};

export default LoadingIndicator;
