import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Table, Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as XLSX from 'xlsx'; // Import xlsx for Excel download
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styles from './AdminOrders.module.css';

const MySwal = withReactContent(Swal);

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [newPaymentStatus, setNewPaymentStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();
  const [showImageModal, setShowImageModal] = useState(false); // State to manage image modal visibility
  const [loadingImages, setLoadingImages] = useState({}); // Track image loading states
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(''); // For the dropdown filter
  const [filteredOrders, setFilteredOrders] = useState([]); // For displaying filtered data
  

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageLoad = (id) => {
    setLoadingImages((prev) => ({ ...prev, [id]: true }));
  };
  

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the image URL
    setShowImageModal(true); // Show the modal
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null); // Clear the image URL
    setShowImageModal(false); // Hide the modal
  };

  

  const handlePrintOrderDetails = async () => {
    const modalContent = document.getElementById('orderDetailsModalContent');
    if (!modalContent) return;
  
    // PDF dimensions
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    // Render the content using html2canvas
    const canvas = await html2canvas(modalContent, {
      scale: 2,
      useCORS: true, // For cross-origin images
    });
  
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
  
    // Scale the content to fit the width of the PDF
    const scale = pdfWidth / imgWidth;
    const scaledHeight = imgHeight * scale;
  
    if (scaledHeight <= pdfHeight) {
      // Content fits within one page
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, scaledHeight);
    } else {
      // Content needs to span multiple pages
      let yPosition = 0;
  
      while (yPosition < imgHeight) {
        const pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(canvas.height - yPosition, pdfHeight / scale);
  
        const pageContext = pageCanvas.getContext('2d');
        pageContext.drawImage(
          canvas,
          0,
          yPosition,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );
  
        const pageImgData = pageCanvas.toDataURL('image/png');
        const pageScaledHeight = (pageCanvas.height * pdfWidth) / canvas.width;
  
        if (yPosition === 0) {
          pdf.addImage(pageImgData, 'PNG', 0, 0, pdfWidth, pageScaledHeight);
        } else {
          pdf.addPage();
          pdf.addImage(pageImgData, 'PNG', 0, 0, pdfWidth, pageScaledHeight);
        }
  
        yPosition += pageCanvas.height;
      }
    }
  
    pdf.save('Detalles_Pedido.pdf');
  };
  
  
  
  
  

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const q = query(collection(firestore, 'orders'));
      const querySnapshot = await getDocs(q);
      const ordersData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const sortedOrders = ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders); // Initialize filteredOrders
      setLoading(false);
    };
  
    fetchOrders();
  }, [firestore]);
  
  const handleStatusFilterChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedStatusFilter(selectedStatus);
  
    if (selectedStatus === '') {
      setFilteredOrders(orders); // Show all orders when no filter is selected
    } else {
      setFilteredOrders(orders.filter((order) => order.status === selectedStatus));
    }
  };
  
  const handleDownloadReport = () => {
    const formattedOrders = orders.map((order) => {
      // Generate product details in the format "Product Name: URL"
      const productDetails = (order.items || [])
        .map(
          (item) => `${item.name}: https://armonyfloristeria.com/products/${item.id}`
        )
        .join(', '); // Join multiple products with commas
  
      return {
        'Número de Orden': order.orderNumber || 'N/A',
        'Fecha': new Date(order.date).toLocaleString('es-CR'),
        'Nombre Cliente': `${order.contactInfo?.recipientName} ${order.contactInfo?.recipientLastName || ''}`,
        'Teléfono': order.contactInfo?.telefono || 'N/A',
        'Email': order.email || 'N/A', // Added email column
        'Productos': productDetails, // Products as plain text
        'Total': order.total,
        'Status Orden': order.status,
        'Status Pago': order.paymentStatus || 'Pago Pendiente',
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
    worksheet['!cols'] = [
      { wch: 20 }, // Order Number
      { wch: 20 }, // Date
      { wch: 30 }, // Client Name
      { wch: 15 }, // Phone
      { wch: 30 }, // Email (New column)
      { wch: 100 }, // Products
      { wch: 15 }, // Total
      { wch: 20 }, // Order Status
      { wch: 20 }, // Payment Status
    ];
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedidos');
  
    // Write file
    XLSX.writeFile(workbook, 'Reporte_Pedidos.xlsx');
  };

  const handleSaveOrderStatus = async () => {
    if (!newStatus) {
      MySwal.fire({ title: 'Seleccione un estado válido', icon: 'warning', confirmButtonText: 'OK' });
      return;
    }
    setLoading(true);
    const orderDocRef = doc(firestore, 'orders', selectedOrder.id);
    await updateDoc(orderDocRef, { status: newStatus });
    setOrders((prevOrders) =>
      prevOrders.map((order) => (order.id === selectedOrder.id ? { ...order, status: newStatus } : order))
    );
    setShowStatusModal(false);
    setLoading(false);

    MySwal.fire({ title: 'Estado de la orden actualizado!', icon: 'success', confirmButtonText: 'OK' });
  };

  const handleSavePaymentStatus = async () => {
    if (!newPaymentStatus) {
      MySwal.fire({ title: 'Seleccione un estado de pago válido', icon: 'warning', confirmButtonText: 'OK' });
      return;
    }
    setLoading(true);
    const orderDocRef = doc(firestore, 'orders', selectedOrder.id);
    await updateDoc(orderDocRef, { paymentStatus: newPaymentStatus });
    setOrders((prevOrders) =>
      prevOrders.map((order) => (order.id === selectedOrder.id ? { ...order, paymentStatus: newPaymentStatus } : order))
    );
    setShowPaymentModal(false);
    setLoading(false);

    MySwal.fire({ title: 'Estado de pago actualizado!', icon: 'success', confirmButtonText: 'OK' });
  };

  const handleOpenStatusModal = (order) => {
    setSelectedOrder(order);
    setNewStatus(order.status);
    setShowStatusModal(true);
  };

  const handleOpenPaymentModal = (order) => {
    setSelectedOrder(order);
    setNewPaymentStatus(order.paymentStatus);
    setShowPaymentModal(true);
  };

  const handleOpenOrderDetailsModal = (order) => {
    setSelectedOrder(order);
    setShowOrderDetailsModal(true);
  };

  const handleCloseModals = () => {
    setShowStatusModal(false);
    setShowPaymentModal(false);
    setShowOrderDetailsModal(false);
    setSelectedOrder(null);
    setNewStatus('');
    setNewPaymentStatus('');
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value);
  };

 

  return (

      <div className={styles.adminOrdersContainer}>
        {loading && <div className={styles.loadingOverlay}></div>}
    
        <h2 className={styles.adminOrdersTitle}>Manejo de Ordenes</h2>
    
        {/* Filter Dropdown */}
        <div className={styles.filterContainer}>
          <label htmlFor="statusFilter" className={styles.filterLabel}>
            Filtrar por Status Orden:
          </label>
          <select
            id="statusFilter"
            className={styles.filterSelect}
            value={selectedStatusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">Todos</option>
            <option value="Pendiente">Pendiente</option>
            <option value="Procesando">Procesando</option>
            <option value="En Espera de Pago">En Espera de Pago</option>
            <option value="Listo para Recolección">Listo para Recolección</option>
            <option value="En camino">En camino</option>
            <option value="Entregado">Entregado</option>
            <option value="Pedido Confirmado">Pedido Confirmado</option>
            <option value="Cancelado">Cancelado</option>
            <option value="Cancelado por el Vendedor">Cancelado por el Vendedor</option>
          </select>
        </div>
    
        <Button onClick={handleDownloadReport} className={styles.adminOrdersButton}>
          Descargar Reporte
        </Button>
    
  
      <Table striped bordered hover className={styles.adminOrdersTable}>
        <thead>
          <tr>
            <th>Número de Orden</th>
            <th>Fecha</th>
            <th>Nombre Cliente</th>
            <th>Productos</th>
            <th>Total</th>
            <th>Status Orden</th>
            <th>Status Pago</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
  {filteredOrders.map((order) => (
    <tr key={order.id}>
      <td>{order.orderNumber || 'N/A'}</td>
      <td>
        {new Date(order.date).toLocaleString('es-CR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })}
      </td>
      <td>
        {order.contactInfo?.recipientName} {order.contactInfo?.recipientLastName || ''}
      </td>
      <td>
        {order.items.map((item, index) => (
          <div key={index} className={styles.productInfo}>
            <span>{item.name}</span>
            <div className={styles.cardImgContainer}>
              {!loadingImages[item.id] && (
                <div className={`${styles.imagePlaceholder} ${styles.spinner}`}></div>
              )}
              <img
                src={item.principalImageUrl}
                alt={item.name}
                className={`${styles.productImage} ${
                  loadingImages[item.id] ? styles.imageLoaded : ''
                }`}
                loading="lazy"
                onLoad={() => handleImageLoad(item.id)}
                onClick={() => handleImageClick(item.principalImageUrl)}
              />
            </div>
          </div>
        ))}
      </td>
      <td>{formatCurrency(order.total)}</td>
      <td>{order.status}</td>
      <td>{order.paymentStatus || 'Pago Pendiente'}</td>
      <td>
        <button
          className={styles.adminOrdersButton}
          onClick={() => handleOpenStatusModal(order)}
        >
          Actualizar Status
        </button>
        <button
          className={styles.adminOrdersButton}
          onClick={() => handleOpenPaymentModal(order)}
        >
          Actualizar Status Pago
        </button>
        <button
          className={styles.adminOrdersButton}
          onClick={() => handleOpenOrderDetailsModal(order)}
        >
          Ver Orden
        </button>
      </td>
    </tr>
  ))}
</tbody>

      </Table>
  
      {/* Modal for Enlarged Image */}
      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Imagen del Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {selectedImage && <img src={selectedImage} alt="Product" className={styles.fullImage} />}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleCloseImageModal} className={styles.modalCloseButton}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  
      {/* Modal for Order Status */}
      <Modal show={showStatusModal} onHide={handleCloseModals}>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Actualizar Orden Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <option disabled value="">
                  Seleccionar Status
                </option>
                <option value="Pendiente">Pendiente</option>
                <option value="Procesando">Procesando</option>
                <option value="En Espera de Pago">En Espera de Pago</option>
                <option value="Listo para Recolección">Listo para Recolección</option>
                <option value="En camino">En camino</option>
                <option value="Entregado">Entregado</option>
                <option value="Pedido Confirmado">Pedido Confirmado</option>
                <option value="Cancelado">Cancelado</option>
                <option value="Cancelado por el Vendedor">Cancelado por el Vendedor</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleSaveOrderStatus} className={styles.modalSaveButton}>
            Guardar
          </Button>
          <Button onClick={handleCloseModals} className={styles.modalCloseButton}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  
      {/* Modal for Payment Status */}
      <Modal show={showPaymentModal} onHide={handleCloseModals}>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Actualizar Status Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Group controlId="paymentStatus">
              <Form.Label>Status Pago</Form.Label>
              <Form.Control
                as="select"
                value={newPaymentStatus}
                onChange={(e) => setNewPaymentStatus(e.target.value)}
              >
                <option disabled value="">
                  Seleccionar Status Pago
                </option>
                <option value="Pago Pendiente">Pago Pendiente</option>
                <option value="Pagado">Pagado</option>
                <option value="Pagado en el Local">Pagado en el Local</option>
                <option value="Cancelado">Cancelado</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleSavePaymentStatus} className={styles.modalSaveButton}>
            Guardar
          </Button>
          <Button onClick={handleCloseModals} className={styles.modalCloseButton}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  
{/* Modal for Viewing Order Details */}
<Modal show={showOrderDetailsModal} onHide={handleCloseModals} size="lg">
  <Modal.Header closeButton className={styles.modalHeader}>
    <Modal.Title>Detalles del Pedido</Modal.Title>
    <Button onClick={handlePrintOrderDetails} className={styles.printButton}>
      Descargar Pedido
    </Button>
  </Modal.Header>
  <Modal.Body className={styles.modalBody}>
    {/* Wrap the modal content in a div with an ID for printing */}
    <div id="orderDetailsModalContent">
      {selectedOrder && (
        <div>
          <h4>Información del Cliente</h4>
          <p>
            <strong>Nombre:</strong> {selectedOrder.contactInfo?.recipientName}{' '}
            {selectedOrder.contactInfo?.recipientLastName || ''}
          </p>
          <p>
            <strong>Teléfono:</strong> {selectedOrder.contactInfo?.telefono || 'N/A'}
          </p>
          <p>
            <strong>Email:</strong> {selectedOrder.email}
          </p>

          {/* "Quien Recibe" Section */}
          <h4>Quien Recibe</h4>
          <p>
            <strong>Nombre:</strong> {selectedOrder.quienRecibe?.name || 'N/A'}
          </p>
          <p>
            <strong>Teléfono:</strong> {selectedOrder.quienRecibe?.phone || 'N/A'}
          </p>

          <h4>Información de Envío</h4>
          {selectedOrder.shippingMethod === 'delivery' ? (
            <div>
              <p>
                <strong>Dirección:</strong> {selectedOrder.shippingInfo?.direccion || 'N/A'}
              </p>
              <p>
                <strong>Provincia:</strong> {selectedOrder.shippingInfo?.provincia || 'N/A'}
              </p>
              <p>
                <strong>Cantón:</strong> {selectedOrder.shippingInfo?.canton || 'N/A'}
              </p>
              <p>
                <strong>Distrito:</strong> {selectedOrder.shippingInfo?.distrito || 'N/A'}
              </p>
              <p>
                <strong>Código Postal:</strong> {selectedOrder.shippingInfo?.codigoPostal || 'N/A'}
              </p>
            </div>
          ) : (
            <p>
              <strong>Retiro en tienda:</strong> Lindora, entre TacoBell y Soda Tapia
            </p>
          )}

          {/* Always show these fields */}
          <p>
            <strong>Fecha de Entrega:</strong> {selectedOrder.deliveryDate || 'N/A'}
          </p>
          <p>
            <strong>Horario de Entrega:</strong> {selectedOrder.deliveryRange || 'N/A'}
          </p>
          <p>
            <strong>Comentarios Adicionales:</strong> {selectedOrder.additionalComments || 'N/A'}
          </p>

          <h4>Información de Pago</h4>
          <p>
            <strong>Método de Pago:</strong> {selectedOrder.paymentMethod || 'N/A'}
          </p>

          <h4>Resumen del Pedido</h4>
          <ul className={styles.orderSummary}>
            {selectedOrder.items.map((item, index) => (
              <li key={index} className={styles.orderItem}>
                <img
                  src={item.principalImageUrl}
                  alt={item.name}
                  className={styles.orderItemImage}
                  onClick={() => handleImageClick(item.principalImageUrl)}
                />
                <div>
                  <p>
                    <strong>{item.name}</strong>
                  </p>
                  <p>
                    {formatCurrency(item.price)} x {item.quantity}
                  </p>
                  {item.option && <p><strong>Opción:</strong> {item.option.name}</p>}
                  {item.tarjetaMessage && (
                    <p>
                      <strong>Mensaje en la Tarjeta:</strong> {item.tarjetaMessage}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <p>
            <strong>Entrega:</strong> {formatCurrency(selectedOrder.deliveryPrice || 0)}
          </p>
          <p>
            <strong>Total:</strong> {formatCurrency(selectedOrder.total)}
          </p>
        </div>
      )}
    </div>
  </Modal.Body>
  <Modal.Footer className={styles.modalFooter}>
    <Button onClick={handleCloseModals} className={styles.modalCloseButton}>
      Cerrar
    </Button>
  </Modal.Footer>
</Modal>



    </div>
  );
  
  
};

export default AdminOrders;
