import React, { useState } from 'react';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useCart } from '../contexts/CartContext';
import { saveCart } from '../services/firestoreService';
import styles from './CustomerRegister.module.css'; // Import the CSS module
import registerBackground from '../images/register3.jpg'; // Import the background image
import { serverTimestamp } from 'firebase/firestore'; // Import serverTimestamp



const CustomerRegister = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    lastName: '',
    dob: '', // Add Date of Birth to the form data
    dateRegistered: serverTimestamp(), // Add timestamp
    ordersPlaced: 0, // Initialize number of orders
    totalSpent: 0,   // Initialize total amount spent
  });
  const navigate = useNavigate();
  const { setCart } = useCart();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.name || !formData.lastName || !formData.email || !formData.password) {
      Swal.fire('Error', 'Todos los campos obligatorios deben ser completados.', 'error');
      return;
    }

    // Validate password criteria
    if (formData.password.length < 8 || !/[A-Z]/.test(formData.password) || !/[0-9]/.test(formData.password)) {
      Swal.fire(
        'Error',
        'La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula y un número.',
        'error'
      );
      return;
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      Swal.fire('Error', 'Las contraseñas no coinciden.', 'error');
      return;
    }

    // Validate Date of Birth (if provided)
    if (formData.dob && new Date(formData.dob) > new Date()) {
      Swal.fire('Error', 'La fecha de nacimiento no puede ser en el futuro.', 'error');
      return;
    }

    try {
      const { email, password, name, lastName, dob } = formData;

      // Check if email is already registered
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        // Email is already registered
        Swal.fire({
          title: 'Correo ya registrado',
          text: 'Este correo ya está registrado en nuestro sistema.',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Iniciar Sesión',
          cancelButtonText: 'Olvidé mi Contraseña',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/login'); // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate('/reset-password'); // Redirect to reset password page
          }
        });
        return; // Stop further execution
      }

      // Register the user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save user data to Firestore
      await setDoc(doc(firestore, 'users', user.uid), {
        email,
        name,
        lastName,
        dob: dob || null,
        cart: [],
        ordersPlaced: 0, // Initialize ordersPlaced
        totalSpent: 0,   // Initialize totalSpent
        dateRegistered: new Date().toISOString(), // Add current date
      });

      // Handle cart (if any)
      const guestCart = JSON.parse(localStorage.getItem('cart')) || [];
      await saveCart(guestCart);
      setCart(guestCart);
      localStorage.removeItem('cart');

      Swal.fire('Éxito', '¡Registro exitoso! Bienvenido.', 'success');
      navigate('/products');
    } catch (error) {
      console.error('Error registering user:', error);
      
      // Check the Firebase error code
      if (error.code === 'auth/email-already-in-use') {
        Swal.fire({
          title: 'Correo ya registrado',
          text: 'Este correo ya está registrado en nuestro sistema.',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Iniciar Sesión',
          cancelButtonText: 'Olvidé mi Contraseña',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/customer/login'); // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate('/forgot-password'); // Redirect to reset password page
          }
        });
      } else {
        // For all other errors, display a generic message
        Swal.fire('Error', 'Hubo un problema al registrar el usuario. Inténtalo de nuevo.', 'error');
      }
    }
    
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${registerBackground})` }}>
      <div className={styles.formWrapper}>
        <h2>Crear Cuenta</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Apellidos"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Contraseña"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirmar Contraseña"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <p>Cumpleaños (Opcional)</p>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              placeholder="Fecha de Nacimiento (opcional)"
            />

          </div>
          <button type="submit" className={styles.btnPrimary}>Registrarse</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerRegister;
